import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';

import layer1 from './images/layer1.png';
import layer2 from './images/layer2.png';
import layer3 from './images/layer3.png';
import layer4 from './images/layer4.png';

var captions = require('./captions.json');


class Button extends React.Component {
  render() {
    return (
      <div className="button">
        <div dangerouslySetInnerHTML={{ __html: this.props.text }} />
        <div />
      </div>
    );
  }
}

class Title extends React.Component {
  render() {
    let inner = <div dangerouslySetInnerHTML={{ __html: this.props.text }} />;

    return (
      <div className="title" style={{fontSize: this.props.size}}>
        {inner}
        {inner}
      </div>
    );
  }
}

class Paragraph extends React.Component {
  render() {
    return (
      <p dangerouslySetInnerHTML={{ __html: this.props.text }}
         className="paragraph" />
    );
  }
}

class Space extends React.Component {
  render() {
    return <div style={{height: this.props.size,
                        width: "100%",
                        position: "relative"}} />;
  }
}


var Cover = function() {
  return (
    <div className="cover">
      <div>
        <img src={layer4} alt="layer4" className="parallax" />
        <img src={layer3} alt="layer3" className="parallax" />
        <img src={layer2} alt="layer2" className="parallax" />
        <img src={layer1} alt="layer1" className="parallax" />
      </div>
      <div>
        <div />
        <div>
          <Title text={captions.cover_title} size="35px" />
          <Space size="50px" />
          <Paragraph text={captions.cover_content} />
          <Space size="40px" />
          <a href={'mailto:' + captions.cover_email}>
            <Button text={captions.cover_button} />
          </a>
        </div>
      </div>
      <div />
    </div>
  );
}


var Card = function() {
  return (
    <div className="card">
      <Title text={captions.card_title} size="30px" />
      <Space size="45px" />
      <div>
        <div>
          <Paragraph text={captions.card_content_one} />
        </div>
        <div>
          <Paragraph text={captions.card_content_two} />
        </div>
      </div>
    </div>
  );
}


var Footer = function() {
  return (
    <div className="footer">
      <div>
        <Title text={captions.footer_title} size="30px" />
      </div>
      <div>
        <Paragraph text={captions.footer_address} />
        <a href={"mailto:" + captions.footer_email}>
          <Paragraph text={captions.footer_email} />
        </a>
      </div>
    </div>
  )
}


class Main extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Cover />
        <Card />
        <Footer />
      </React.Fragment>
    );
  }
}

ReactDOM.render(
  <Main />,
  document.getElementById('main')
);
